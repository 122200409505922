<template>
  <div>
    <div
      v-show="displayTheLastPos"
      class="modal fade"
      :class="{ show: displayTheLastPos, displayModal: displayTheLastPos }"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" id="exampleModalDialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <a id="close" class="close" v-on:click="closePathLastPos" aria-label="Close">X</a>
            <div id="options">
              <button id="displayPath" style="display:none" class="btn btn-primary btn-semi">Afficher</button><br />
            </div>

            <div id="iframeMap" class="fullHeightUnderNavbar"></div>

            <div style="display: none;">
              <div id="popup"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import '../../../../public/geoportail/OpenLayers/ol.css';
// import '../../../../public/geoportail/GpPluginOpenLayers/GpPluginOpenLayers.css';
// import '../../../../public/geoportail/OpenLayers/ol.js';
// import '../../../../public/geoportail/GpPluginOpenLayers/GpPluginOpenLayers.js';
// import '../../../../public/geoportail/js/ShowPaths.js';
// import '@/sass/_map.scss';
import { mapGetters } from "vuex";

export default {
  name: "last-position",
  data() {
    return {
      displayTheLastPos: false,
    };
  },
  methods: {
    displayLastPosition() {
      // $('#exampleModal').addClass('show');
      // $('#exampleModal').addClass('fullScreenModal');
      // $('#exampleModal').addClass('displayModal');
      this.displayTheLastPos = !this.displayTheLastPos;

      let bOrT = this.currentMachine.motorized === true ? "tracker" : "beacon";

      let startDate = this.currentMachine.lastSeenDate;
      let time = this.startHour(startDate);
      let duration = 10;

      startDate = startDate.slice(0, 10);

      document.getElementById("iframeMap").innerHTML =
        '<object type="text/html" data="http://localhost:8000/geoportail/parcours" width="100%" height="100%">\
                                                             <param value="displayLastPos" BorT="' +
        bOrT +
        '" beaconId="' +
        this.currentMachine.beacon +
        '"trackerId="' +
        this.currentMachine.tracker +
        '" startDate="' +
        startDate +
        '"\
                                                             time="' +
        time +
        '" duration="' +
        duration;
    },

    startHour(startDate) {
      // On parcours la date de début et on récupère les heures et les minutes
      var hour = startDate.slice(11, 13);
      var min = startDate.slice(14, 16);

      // Vérification afin de retourner la bonne heure de début
      if (min == "00") {
        if (hour == "00") return "23:59";
        else {
          var newHour = (Number(hour) - 1).toString(10);
          if (newHour.length == 1) newHour = "0" + newHour;
          return newHour + ":59";
        }
      } else {
        var newMin = (Number(min) - 1).toString(10);
        if (newMin.length == 1) newMin = "0" + newMin;
        return hour + ":" + newMin;
      }
    },

    closePathLastPos() {
      this.displayTheLastPos = !this.displayTheLastPos;
    },
  },
  computed: {
    ...mapGetters(["currentMachine"]),
  },
};
</script>

<style>
.displayModal {
  display: block;
}
</style>
