<template>
  <div>
    <b-modal
      ref="pathModal"
      dialog-class="modal-no-max-width"
      @show="resetSelection"
      @hide="resetSelection"
      size="xl"
      hide-header
      hide-footer
    >
      <div class="d-block text-center pathModalContent">
        <a href="javascript:void(0)" @click="$refs['pathModal'].hide()" class="close-button"></a>
        <CropfieldsMap ref="map" :pathOptions="pathOptions" :minimal="true" :fullScreenButton="false" :isOnCropfieldView="false"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CropfieldsMap from "@/views/cropfields/CropfieldsMap.vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import MachineApi from "@/api/machine.api";
import { intervalMinutes, addDuration } from "@/utils/commons";

export default {
  name: "modalPathActivity",
  data() {
    return {
      fromMachine: false,
    };
  },
  mounted() {
  },
  methods: {
    ...mapMutations({
      resetCurrentId: "cropfield/resetCurrentId",
      setEntriesChecked: "cropfield/setEntriesChecked",
    }),
    resetSelection() {
      this.resetCurrentId();
      this.setEntriesChecked({ entries: [] });
    },
    displayPathActivity({ fromMachine } = { fromMachine: false }) {
      this.fromMachine = fromMachine;
      this.$refs["pathModal"].show();
    },
  },
  computed: {
    ...mapGetters({
      currentActivity: "activity/currentEntry",
      currentMachine: "machine/currentEntry"
    }),
    pathOptions() {
      return this.fromMachine ? this.machinePathOptions : this.activityPathOptions;
    },
    machinePathOptions() {
      return {
        machine: this.currentMachine.id,
        startDate: this.currentMachine.lastSeenDate,
        shiftMinutes: 20,
        duration: 40,
        fromMachine: true,
      };
    },
    activityPathOptions() {
      // si l'heure de fin est définie => durée du parcours (minutes) = int((heure de fin - heure de début + 10s)/60) + 1
      // sinon : durée du parcours (minutes) = minimum(int((maintenant + 5 minutes - heure de début)/60), 600)
      let currentDate = new Date();
      currentDate.setMinutes(currentDate.getMinutes() + 5);
      currentDate = currentDate.toString();
      return {
        activity: this.currentActivity.id,
        startDate: this.currentActivity.startDate,
        shiftMinutes: 0.17,
        duration: this.currentActivity.endDate
          ? intervalMinutes({ startDate: this.currentActivity.startDate, endDate: this.currentActivity.endDate, secondsAdded: 10 }) + 1
          : Math.min(intervalMinutes({ startDate: this.currentActivity.startDate, endDate: currentDate }), 600),
        fromMachine: false,
      };
    },
  },
  components: {
    CropfieldsMap,
  },
};
</script>

<style>
.displayModal {
  display: block;
}

.buttonModalPathActivity {
  display: none;
}

.pathModalContent {
  height: 90vh;
}

.modal-body {
  padding: 0 !important;
}

.close-button {
  z-index: 10;
  position: absolute;
  right: 0px;
  top: 0px;
  width: 42px;
  height: 41px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  padding-top: 4px;
  background: #dc3545;
  opacity: 1;
}

.close-button:hover {
  opacity: 0.75;
}

.close-button:before,
.close-button:after {
  position: absolute;
  left: 19px;
  content: " ";
  height: 32px;
  width: 2px;
  background-color: #ffffff;
}

.close-button:before {
  transform: rotate(45deg);
}

.close-button:after {
  transform: rotate(-45deg);
}
</style>
