<template>
  <div>

    <!--Type && AntiDrift-->
    <b-row>
      <b-col cols="6">
        <h4 class="subtitle-form">{{ $t("machines.fields.machine_type") }}</h4>
      </b-col>
      <b-col v-if="currentEntry.machineType_antiDriftOption" cols="6">
        <h4 class="subtitle-form">{{ $t("machines.fields.anti_drift") }}</h4>
      </b-col>
    </b-row>

    <b-row class="mb-2" v-if="currentEntry.machineType_antiDriftOption">
      <b-col cols="6">
        <span class="text-infos shape-first-cell bg-white">{{ currentEntry.machineType_designation }}</span>
      </b-col>
        <b-col cols="6">
         <span class="text-infos shape-first-cell bg-white">{{ antiDriftValueText }}</span>
       </b-col>
    </b-row>
    <b-row class="mb-2" v-else>
      <b-col cols="12">
        <span class="text-infos shape-first-cell bg-white">{{ currentEntry.machineType_designation }}</span>
      </b-col>
    </b-row>

    <!-- Width -->
    <h4 class="subtitle-form">{{ $t("machines.fields.size") }}</h4>
    <p>
      <span
        class="text-infos shape-first-cell bg-white"
        v-if="currentEntry.width"
      >{{ currentEntry.width }} {{ getUnitText(currentEntry.widthUnit) }}</span>
      <span class="text-infos shape-first-cell bg-white" v-else>Aucune largeur renseignée</span>
    </p>

    <!-- last location -->
    <h4 class="subtitle-form">{{ $t("machines.details.last_location") }}</h4>
    <table>
      <tr
        :class="{
          'last-postion': currentEntry.lastSeenDate != null,
          'no-position': currentEntry.lastSeenDate == null,
        }"
        @click="openModal"
      >
        <td class="text-infos shape-first-cell">
          <img :src="locationIcon" class="imgTd" alt="location" />
        </td>

        <td class="text-infos shape-second-cell">
          <strong>{{ currentEntry.lastSeenDate ? "Le " + formatDate(currentEntry.lastSeenDate, "date") : $t("location_unknown")}}</strong>
        </td>
      </tr>
    </table>

    <!-- Tracker -->
    <div v-if="currentEntry.motorized === true">
      <h4 class="subtitle-form">Tracker</h4>
      <table>
        <tr>
          <td class="text-infos shape-first-cell">
            <img :src="trackerIcon" alt="tracker" />
          </td>

          <td
            class="text-infos shape-second-cell"
          >{{ currentEntry.tracker_serialNumber | empty("Aucun tracker associé") }}</td>
        </tr>
      </table>
    </div>

    <!-- Beacon -->
    <div v-else>
      <h4 class="subtitle-form">{{ $t("machines.fields.beacon") }}</h4>
      <table>
        <tr>
          <td class="text-infos shape-first-cell">
            <img :src="beaconIcon" alt="beacon" />
          </td>

          <td
            class="text-infos shape-second-cell"
          >{{ currentEntry.beacon_name | empty("Aucune balise associée") }}</td>
        </tr>
      </table>
    </div>

    <hr>
    <!-- ask for tool -->
    <div class="mb-2" v-if="currentEntry.motorized">
      <h4 class="subtitle-form">{{ $t("machines.fields.askForTool") }}</h4>
      <span class="text-infos shape-first-cell bg-white">{{ currentEntry.askForTool ? $t("yes") : $t("no") }}</span>
    </div>
    <!-- don't ask for inputs -->
    <div class="mb-2" v-if="currentActivityType.needInputs">
      <h4 class="subtitle-form">{{ $t("machines.fields.dontAskForInputs") }}</h4>
      <span class="text-infos shape-first-cell bg-white">{{ currentEntry.dontAskForInputs ? $t("enabled") : $t("disabled") }}</span>
    </div>

    <LastPosition ref="lastPosition" />

    <ActivityPath ref="modalPath"></ActivityPath>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import beacon from "@/assets/images/beacon.svg";
import tracker from "@/assets/images/tracker.svg";
import location from "@/assets/images/location-pin.svg";
import LastPosition from "@/components/machines/modals/LastPosition.vue";
import ActivityPath from "@/components/activity/modals/ActivityPath.vue";
import { i18n } from '../../../i18n';
import {formatDate} from "@/utils/commons";

export default {
  name: "machine-info",
  props: {
    currentEntry: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      beaconIcon: beacon,
      trackerIcon: tracker,
      locationIcon: location,
      units: [
        { value: "m", text: i18n.t("unitDenomination.unit_m") },
        { value: "rg", text: i18n.t("unitDenomination.unit_rg") },
      ],
    };
  },
  components: {
    LastPosition,
    ActivityPath,
  },
  async created() {
    // On requête sur le type de l'activité pour savoir si elle nécessite des intrants
    // Le cas échéant, l'information "Pas de saisie d'intrant" sera affichée
    if(this.currentEntry?.machineType || this.currentEntry.machineType == 0) {
      await this.$store.dispatch("getActivityTypeFromMachineTypes", [this.currentEntry.machineType]);
    }
  },
  methods: {
    openModal() {
      this.$refs.modalPath.displayPathActivity({ fromMachine: true });
    },
    formatDate,
    getUnitText(value) {
      let text = ""
      this.units.map(unit => {
        if(unit.value == value)
          text = unit.text;
      });
      return text;
    }
  },
  computed: {
    ...mapGetters({
      currentMachine: "machine/currentEntry",
      currentActivityType: "currentActivityType"
    }),
    antiDriftValueText() {
    return this.currentEntry.antiDrift
      ? this.$t("cropfields.list.values.active.true")
      : this.$t("cropfields.list.values.active.false");
  },
  },
};
</script>

<style lang="scss" scoped>
tr {
  background-color: $secondary-light-color;
}

.no-position {
  cursor: default;
  opacity: 0.6;
  filter: alpha(opacity = 60);
}

.last-postion {
  cursor: pointer;
}

.last-postion:hover {
  background-color: $secondary-color;
}

/**
 * Shape <td> for the first cell.
 */
.shape-first-cell {
  border-radius: 3px 0 0 3px;
  padding-right: 40px;
  padding-left: 8px;
  height: 50px;
}

/**
 * Shape <td> for the second cell.
 */
.shape-second-cell {
  border-radius: 0 3px 3px 0;
  font-weight: normal;
  width: 180px;
  height: 50px;
}

table {
  border-collapse: separate;
  border-spacing: 0 5px;
}

// .imgTd {
img {
  width: 2.5em;
  // float: left;
  // margin-right: 5px;
}
// }
</style>
